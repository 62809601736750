import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {
  API_URL_PREFIX,
  EomUpdateDataDto,
  GenerationTaskCreateBodyDto,
  GenerationTaskDataDto,
  GenerationTaskDto,
  GenerationTaskListItemDto,
  GenerationTaskSerializedDto,
  GenerationTasksGetParams,
  GenerationTaskSubsystemType,
  GenerationTaskUpdateBody,
  GenerationTaskUpdateDataDto,
  GenerationTaskWithSourceVariantDto,
  HttpClientGetOptions,
  HvacUpdateDataDto,
  LayoutGenerationTaskUpdateBody,
  LayoutSectionUpdateDataDto,
  OMARGenerationTaskDto,
  OMARGenerationTaskUpdateBody,
  PlumbingUpdateDataDto,
  SpaceplanningFeatureToggleType,
  SpaceplanningUpdateDataDto,
  StructureGenerationTaskDto,
  StructureGenerationTaskUpdateBody,
} from '@generation-api-v2/util';
import {GATEWAY_URL} from '@shared/util';
import {map, Observable, switchMap} from 'rxjs';

@Injectable({providedIn: 'root'})
export class GenerationTaskApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  generateVariants(generationTaskId: string, featureToggles?: SpaceplanningFeatureToggleType): Observable<null> {
    const headers = featureToggles ? {FeatureToggles: featureToggles} : {};

    return this.http.post<null>(
      `${this.gatewayUrl}/${this.apiPrefix}/GenerationTasks/${generationTaskId}/generate`,
      null,
      {
        headers,
      },
    );
  }

  /**
   * Для структуризации хранимой информации о задании фронт самостоятелньо определяет
   * типизацию сериализованного объекта. Который в последствии десериализуется в строку
   * при отправке на бэк.
   */
  updateTask(id: string, taskData: GenerationTaskUpdateBody): Observable<GenerationTaskDto>;
  /**
   * Для структуризации хранимой информации о задании фронт самостоятелньо определяет
   * типизацию сериализованного объекта. Который в последствии десериализуется в строку
   * при отправке на бэк.
   *
   * `T` – модель объекта задания для подсистемы
   *
   * Бэк хранит эту строку в БД в исходном виде. В случае обнаружения бэком в сериализованном
   * объекте из этой строки поля `referenceDataHardcoded` или `referenceData`, он начинает
   * взаимодействовать с ним, как с объектом подготовленным к заполнению с учетом справочников.
   * В ином случае строка будет передана в подсистему как есть.
   *
   * ### Работа с подстановками
   * Свойства из объекта `referenceDataHardcoded` будут использованы как расширение объекта `generationData`
   * перед передачей в подситему:
   *
   * ```ts
   * data = {
   *   generationData: {
   *     foo: 'bar',
   *   },
   *   referenceDataHardcoded: {
   *     hardcodeCatalogName: {some: 'thing'}
   *   }
   * }
   * // До подсистемы дойдет в виде:
   * data = {
   *   foo: 'bar',
   *   hardcodeCatalogName: {some: 'thing'},
   * }
   * ```
   *
   * Свойство из объекта `referenceData`, а именно: айдюк и версия справочника, будут использованы для
   * сбора значений из справочника и расширения объекта `generationData`
   * перед передачей в подситему:
   *
   * ```ts
   * data = {
   *   generationData: {
   *     foo: 'bar',
   *   },
   *   referenceData: {id: 'guid', version: 2},
   * }
   * // До подсистемы дойдет в виде:
   * data = {
   *   foo: 'bar',
   *   [catalog.nick]: {
   *      // ... catalog values
   *   },
   * }
   * ```
   */
  updateTask<D>(id: string, taskData: GenerationTaskUpdateDataDto<D>): Observable<GenerationTaskSerializedDto<D>>;
  updateTask<D>(
    id: string,
    taskData: GenerationTaskUpdateDataDto<D> | GenerationTaskUpdateBody,
  ): Observable<GenerationTaskDto | GenerationTaskSerializedDto<D>> {
    const body: GenerationTaskUpdateBody = {};

    /**
     * Для сохранения обратной совместимости всех существующих реализаций заданий для подсистем
     * оставляю возможность использовать чистую строку как как валидное задание
     *
     * @todo Использование строки как типа для параметра `data` нужно убрать и
     * оставить возможность работать только с сериализованными объектами
     */
    if (!!taskData.data && typeof taskData.data === 'string') {
      body.data = taskData.data;
    } else {
      body.data = JSON.stringify(taskData.data);
    }

    body.description = taskData.description ? taskData.description : '';

    /** Обязательно указываем, что реальный метод на бэке возвращает именно строку в `data` */
    return this.http
      .patch<GenerationTaskDto>(`${this.gatewayUrl}/${this.apiPrefix}/GenerationTasks/${id}`, body, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        map(apiResponse => {
          /**
           * Если мы знаем используем метод без указания возращаемого типа, то
           * сериализацию объекта внутри не производится
           */
          if (typeof taskData === 'string') {
            return apiResponse;
          }

          /**
           * Дополнительно проверяем, что объект `data` был передан и не пуст
           */
          if (apiResponse?.data) {
            return {
              ...apiResponse,
              /**
               * Пробуем сериализовать строку хранимую на бэке в объект с
               * указанной при вызове метода типизацией
               */
              /* TODO: теперь должен всегда возвращаться объект, поправить после согласования с бэком */
              data: typeof apiResponse.data === 'string' ? (JSON.parse(apiResponse.data) as D) : apiResponse.data,
            };
          }

          return apiResponse;
        }),
      );
  }

  getFilteredGeneratedTask(
    rawQueryParams?: GenerationTasksGetParams,
  ): Observable<readonly GenerationTaskListItemDto[]> {
    const requestOptions: HttpClientGetOptions = {};

    if (rawQueryParams !== undefined) {
      requestOptions.params = rawQueryParams;
    }

    return this.http.get<readonly GenerationTaskListItemDto[]>(
      `${this.gatewayUrl}/${this.apiPrefix}/GenerationTasks`,
      requestOptions,
    );
  }

  /* == Порядок следования перегрузок метода ВАЖЕН == */
  /**
   * Если не указан тип для возвращаемой в ответе модели задания, то оставляем её
   * строкой.
   */
  getGeneratedTask(id: string, featureToggles?: SpaceplanningFeatureToggleType): Observable<GenerationTaskDto>;
  /**
   * Если указан тип для возвращаемой в ответе модели задания, метод распарсит значение
   * внутри и сериализует его в понятный тип.
   */
  getGeneratedTask<D>(
    id: string,
    featureToggles?: SpaceplanningFeatureToggleType,
  ): Observable<GenerationTaskSerializedDto<D>>;
  getGeneratedTask<D>(
    id: string,
    featureToggles?: SpaceplanningFeatureToggleType,
  ): Observable<GenerationTaskDto | GenerationTaskSerializedDto<D>> {
    const headers = featureToggles ? {FeatureToggles: featureToggles} : {};

    return this.http
      .get<GenerationTaskDto>(`${this.gatewayUrl}/${this.apiPrefix}/GenerationTasks/${id}`, {
        headers,
      })
      .pipe(
        map(apiResponse => {
          /**
           * @todo удалить этот fallback после очистки БД от старых генераций
           *
           * Пока в БД присутствуют задания с двойным экранированием, десериализация
           * со стороны бэка работает не до конца и парсинг со стороны фронта требуется
           * для сохранения обратной совместимости
           */
          if (typeof apiResponse.data === 'string') {
            /**
             * Придется предварительно распарсить строку, чтобы понять
             * в каком виде хранится данное задание
             */
            let parsedData = JSON.parse(apiResponse.data) as D;

            if ((parsedData as GenerationTaskDataDto<D>).generationData === undefined) {
              try {
                const deeperParsedData = JSON.parse(parsedData as string) as D;

                if ((deeperParsedData as GenerationTaskDataDto<D>).generationData === undefined) {
                  return apiResponse as GenerationTaskDto;
                }

                parsedData = deeperParsedData;
              } catch {
                return apiResponse as GenerationTaskDto;
              }
            }

            /**
             * Дополнительно проверяем, что объект `data` был передан и не пуст
             */
            if (apiResponse?.data) {
              return {
                ...apiResponse,
                /**
                 * Перезаписываем строку сериализованным объектом
                 */
                data: parsedData,
              } as GenerationTaskSerializedDto<D>;
            }
          }

          return apiResponse as GenerationTaskDto;
        }),
      );
  }

  createTask<D = string>(
    rawBody: GenerationTaskCreateBodyDto<D>,
    featureToggles?: SpaceplanningFeatureToggleType,
  ): Observable<string> {
    const headers = featureToggles ? {FeatureToggles: featureToggles} : {};
    let body: GenerationTaskCreateBodyDto = null;

    /**
     * Для сохранения обратной совместимости всех существующих реализаций заданий для подсистем
     * оставляю возможность использовать чистую строку как валидное задание
     *
     * @todo Использование строки как типа для параметра `data` нужно убрать и
     * оставить возможность работать только с сериализованными объектами
     */
    if (typeof rawBody.data === 'string') {
      body = rawBody as GenerationTaskCreateBodyDto;
    } else {
      body = {
        ...rawBody,
        data: JSON.stringify(rawBody.data),
      };
    }

    return this.http.post<string>(`${this.gatewayUrl}/${this.apiPrefix}/GenerationTasks`, body, {
      headers,
    });
  }

  stopGeneration(generationTaskId: string): Observable<string> {
    return this.http.post<string>(
      `${this.gatewayUrl}/${this.apiPrefix}/GenerationTasks/${generationTaskId}/generate/cancel`,
      null,
    );
  }

  getParents(id: string): Observable<readonly GenerationTaskWithSourceVariantDto[]> {
    return this.http.get<readonly GenerationTaskWithSourceVariantDto[]>(
      `${this.gatewayUrl}/${this.apiPrefix}/GenerationTasks/${id}/parents`,
    );
  }

  getParentTaskConfiguration<T>(id: string, subsystem: GenerationTaskSubsystemType): Observable<T> {
    return this.getParents(id).pipe(
      switchMap(data => {
        const layoutGenerationTaskId = data.find(f => f.subsystem === subsystem)?.id ?? null;

        return this.getGeneratedTask<T>(layoutGenerationTaskId);
      }),
      map(d => d.data),
    );
  }

  delete(id: string): Observable<null> {
    return this.http.delete<null>(`${this.gatewayUrl}/${this.apiPrefix}/GenerationTasks/${id}`);
  }

  updateLayoutTask(id: string, taskData: LayoutGenerationTaskUpdateBody): Observable<GenerationTaskDto> {
    return this.http.patch<GenerationTaskDto>(
      `${this.gatewayUrl}/${this.apiPrefix}/GenerationTasks/${id}/layout`,
      taskData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  updateLayoutSectionsTask(id: string, taskData: LayoutSectionUpdateDataDto): Observable<GenerationTaskDto> {
    return this.http.patch<GenerationTaskDto>(
      `${this.gatewayUrl}/${this.apiPrefix}/GenerationTasks/${id}/layout-sections`,
      taskData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  updateStructureTask(id: string, taskData: StructureGenerationTaskUpdateBody): Observable<StructureGenerationTaskDto> {
    return this.http.patch<StructureGenerationTaskDto>(
      `${this.gatewayUrl}/${this.apiPrefix}/GenerationTasks/${id}/structure`,
      taskData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  updateEomTask(id: string, taskData: EomUpdateDataDto) {
    return this.http.patch<GenerationTaskDto>(
      `${this.gatewayUrl}/${this.apiPrefix}/GenerationTasks/${id}/eom`,
      taskData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  updateHvacTask(id: string, taskData: HvacUpdateDataDto) {
    return this.http.patch<GenerationTaskDto>(
      `${this.gatewayUrl}/${this.apiPrefix}/GenerationTasks/${id}/hvac`,
      taskData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  updateSpaceplaningTask(id: string, taskData: SpaceplanningUpdateDataDto) {
    return this.http.patch<GenerationTaskDto>(
      `${this.gatewayUrl}/${this.apiPrefix}/GenerationTasks/${id}/space-planning`,
      taskData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  updatePlumbingTask(id: string, taskData: PlumbingUpdateDataDto) {
    return this.http.patch<GenerationTaskDto>(
      `${this.gatewayUrl}/${this.apiPrefix}/GenerationTasks/${id}/plumbing`,
      taskData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  updateSpaceplaningSlotsTask(id: string, taskData: SpaceplanningUpdateDataDto) {
    return this.http.patch<GenerationTaskDto>(
      `${this.gatewayUrl}/${this.apiPrefix}/GenerationTasks/${id}/space-planning-slots`,
      taskData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  updateOMARTask(id: string, taskData: OMARGenerationTaskUpdateBody) {
    return this.http.patch<OMARGenerationTaskDto>(
      `${this.gatewayUrl}/${this.apiPrefix}/GenerationTasks/${id}/omar`,
      taskData,
    );
  }
}
